import { createStore } from 'vuex'
import axios from 'axios';
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    state: {
        isLoggedIn: false,
        admin: null,
        sidebarVisible: '',
        sidebarUnfoldable: false,
        user: {
            isUserLoggedIn: false,
            token: null,
            userData : null,
        },
    },
    mutations: {
        toggleSidebar(state) {
            state.sidebarVisible = !state.sidebarVisible
        },
        toggleUnfoldable(state) {
            state.sidebarUnfoldable = !state.sidebarUnfoldable
        },
        updateSidebarVisible(state, payload) {
            state.sidebarVisible = payload.value
        },
        SET_ADMIN(state, admin) {
            state.isLoggedIn = true;
            state.admin = admin;
            localStorage.setItem('admin', JSON.stringify(admin));  // Persist in local storage
        },
        LOGOUT(state) {
            state.isLoggedIn = false;
            state.admin = null;
            localStorage.removeItem('admin');  // Clear local storage
        },
        UPDATE_ADMIN_PROFILE(state, updatedAdmin) {
            state.admin.full_name = updatedAdmin.full_name;
            state.admin.email = updatedAdmin.email;
            state.admin.profile_image = updatedAdmin.profile_image;
            localStorage.setItem('admin', JSON.stringify(state.admin));  // Update local storage
        },
        // user mutations
        SET_USER(state, user) {
            state.user.isUserLoggedIn = true;
            state.user.userData = {
                _id: user.userData._id,
                fullname: user.userData.fullname,
                email: user.userData.email,
                status: user.userData.status,
            };
            state.user.token = user.token;
        },
        LOGOUT_USER(state) {
            state.user.isUserLoggedIn = false;
            state.user.token = null;
            state.user.userData = null;
        },
        UPDATE_USER_PROFILE(state, updatedUser) {
            state.user.userData.fullname = updatedUser.fullname,
            state.user.userData.email = updatedUser.email
        },
    },
    actions: {
        initializeStore({ commit }) {
            const admin = localStorage.getItem('admin');
            if (admin) {
                commit('SET_ADMIN', JSON.parse(admin));
            }

            const user = localStorage.getItem("user");
            if (user) {
                commit("SET_USER", JSON.parse(user));
            }
        },
        login({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                axios.post('/api/admin/login', credentials)
                    .then((response) => {
                        if (response.data && response.data.success == true) {
                            const admin = response.data.data;
                            commit('SET_ADMIN', admin);
                            resolve(admin);
                        } else {
                            reject(response.data.message);
                        }
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        logout({ commit }) {
            commit('LOGOUT');
        },
        updateAdminProfile({ commit }, updatedAdmin) {
            commit('UPDATE_ADMIN_PROFILE', updatedAdmin);
        },
        // actions
        loginUser({ commit }, credentials) {
            return new Promise((resolve, reject) => {
                axios.post("/api/user/auth/v1/sign-in", {
                    email: credentials.email,
                    password: credentials.password,
                    device_name: credentials.device_name,
                    device_token: credentials.device_token,
                })
                .then((response) => {
                    if (response.data && response.data.success === true) {
                        const frontenduser = response.data.data;
                        commit("SET_USER", frontenduser);
                        resolve(response);
                    } else {
                        reject(response.data.message);
                    }
                })
                .catch((error) => {
                    console.error("Axios error:", error);
                    reject(error);
                });
            });
        },
        logoutUser({ commit }) {
            commit("LOGOUT_USER");
        },
        updateUserProfile({ commit }, updatedUser) {
            commit('UPDATE_USER_PROFILE', updatedUser);
        }
    },
    getters: {
        isLoggedIn: (state) => state.isLoggedIn,
        currentAdmin: (state) => state.admin,
        isUserLoggedIn: (state) => state.user.isUserLoggedIn,
        currentUser: (state) => state.user,
    },
    plugins: [createPersistedState()],
});

export default store;