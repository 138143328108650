<template>
	<CSidebar
		position="fixed"
		:unfoldable="sidebarUnfoldable"
		:visible="sidebarVisible"
		@visible-change="
		(event) =>
			$store.commit({
			type: 'updateSidebarVisible',
			value: event,
		})
	">
		<CSidebarBrand>
			<img class="sidebar-brand-full" alt="Apolide Travel Logo" width="150" :src="require('@/assets/images/name-logo.png')" />
			<img class="sidebar-brand-narrow" width="35" :src="require('@/assets/images/sidebar-logo.png')" />
		</CSidebarBrand>
		<CSidebarNav>
		<CNavItem>
			<router-link class="nav-link" :to="{ name: 'Dashboard' }" exact exact-active-class="active"><CIcon  customClassName="nav-icon" icon="cil-speedometer"/> Dashboard</router-link>
		</CNavItem>
		<!-- User Management -->
		<CNavItem>
			<router-link :class="{ active: isRouteActive(['User List','User Create','User Edit']) }" class="nav-link" :to="{ name: 'User List' }"><CIcon  customClassName="nav-icon" icon="cil-user"/> User Management</router-link>
		</CNavItem>
		<!-- Guide Management -->
		<CNavItem>
			<router-link :class="{ active: isRouteActive(['Guide List','Guide Create','Guide Edit']) }" class="nav-link" :to="{ name: 'Guide List' }"><CIcon  customClassName="nav-icon" icon="cil-newspaper"/> Guide Management</router-link>
		</CNavItem>
		<!-- Inquiry Management -->
		<CNavItem>
			<router-link :class="{ active: isRouteActive(['Inquiry List', 'Inquiry View']) }" class="nav-link" :to="{ name: 'Inquiry List' }"><CIcon  customClassName="nav-icon" icon="cil-comment-bubble"/> Inquiry Management</router-link>
		</CNavItem>
		<!-- Newsletter -->
		<CNavItem>
			<router-link :class="{ active: isRouteActive(['Newsletter List']) }" class="nav-link" :to="{ name: 'Newsletter List' }"><CIcon  customClassName="nav-icon" icon="cil-envelope-open"/> Newsletter</router-link>
		</CNavItem>
		<!-- Transaction History -->
		<CNavItem>
			<router-link :class="{ active: isRouteActive(['Transaction History List']) }" class="nav-link" :to="{ name: 'Transaction History List' }"><CIcon  customClassName="nav-icon" icon="cil-dollar"/> Transaction History</router-link>
		</CNavItem>
	</CSidebarNav>
	<CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('toggleUnfoldable')"/>
	</CSidebar>
</template>
  
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
// import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
	name: 'AppSidebar',
	data() {
		return {
            isDeveloper:false
		}
	},
	setup() {
		const store = useStore()
		return {
			logoNegative,
			sygnet,
			sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
			sidebarVisible: computed(() => store.state.sidebarVisible),
		}
	},
	methods: {
		isRouteActive(routeName) {
			return routeName.includes(this.$route.name);
		}
	}
}
</script>

<style scoped>

</style>
