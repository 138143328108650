<template>
	<div class="wrapper">
		<main-header></main-header>
		<main>
			<slot></slot>
		</main>
		<main-footer></main-footer>
	</div>
</template>
  
<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter.vue'
    
    export default {
        name: 'DefaultLayout',
        components: {
            MainHeader,
            MainFooter
        }
    };
</script>
  
<style>

</style>