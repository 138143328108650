<template>
	<CDropdown variant="nav-item">
		<CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
			<CAvatar :src="(loggedInAdmin && loggedInAdmin.profile_image) ? loggedInAdmin.profile_image : avatar" />
		</CDropdownToggle>
		<CDropdownMenu class="pt-0" style="width: 300px;">
			<CDropdownHeader component="h6" class="bg-light fw-semibold py-3 profile-box">
				<div style="display: flex;align-items: center;gap: 15px;">
					<div><CAvatar :src="(loggedInAdmin && loggedInAdmin.profile_image) ? loggedInAdmin.profile_image : ''" /></div>
					<div>
						<dt>{{ (loggedInAdmin && loggedInAdmin.full_name) ? loggedInAdmin.full_name : "" }}</dt>
						{{ (loggedInAdmin && loggedInAdmin.email) ? loggedInAdmin.email : "" }}
					</div>
				</div>
			</CDropdownHeader>
			<CDropdownHeader component="h6" class="bg-light fw-semibold py-2">Settings</CDropdownHeader>

			<router-link class="dropdown-item" :to="{ name: 'Profile Update' }"><CIcon :icon="icon.cilUser"/> Profile</router-link>

			<router-link class="dropdown-item" :to="{ name: 'Change Password' }"><CIcon :icon="icon.cilLockUnlocked" /> Change Password</router-link>

			<CDropdownDivider />
			<CDropdownItem href="#" v-if="$store.state.isLoggedIn" @click="logout">
				<CIcon :icon="icon.cilPowerStandby" /> Logout
			</CDropdownItem>
		</CDropdownMenu>
	</CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg';
import { CIcon } from '@coreui/icons-vue';
import * as icon from '@coreui/icons';
import axios from 'axios';

export default {
	name: 'AdminHeaderDropdownAccnt',
	components: {
		CIcon
	},
	setup() {
		return {
			avatar: avatar,
			itemsCount: 42,
			icon,
		}
	},
	computed: {
		loggedInAdmin() {
			return this.$store.getters.currentAdmin;
		}
	},
	methods: {
		logout() {
			this.$store.dispatch('logout');
			//Make an API call to logout
			axios.get('/api/admin/logout')
			.then((response) => {
                if (response.data.success === true) {
                    this.$toast.success(response.data.message, {
                        position: 'top-right',
                        duration: 3000,
                    });
					this.$router.push({ name: 'Login' });
                } else {
                    this.$toast.error(response.data.message, {
                        position: 'top-right',
                        duration: 3000,
                    });
                }
            })
            .catch((error) => {
				console.log("error",error)
                if (error.response && error.response.data && error.response.data.message) {
                    this.$toast.error(error.response.data.message, {
                        position: 'top-right',
                        duration: 3000,
                    });
                } else {
                    this.$toast.error('An error occurred. Please try again later.', {
                        position: 'top-right',
                        duration: 3000,
                    });
                }
            });
		}
	}
}
</script>

<style scoped>
.profile-box{
	background-color: #1B1C2F!important;
	color: #FFF !important;
	border-radius: 5px 5px 0 0 !important;
	font-weight: 200 !important;
}

.avatar-img {
    width: 150%;
    height: 100%;
    border-radius: 50em;
}


</style>